import { OCTIUM } from '../types';
export default {
  type: OCTIUM,
  service: 'bankcex.com-ws',
  url: 'wss://mainnet-rpc.rupayx.com/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
