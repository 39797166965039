import tokens from '@/_generated/tokens/tokens-rpx.json';
import octium from '@/assets/images/networks/Fiaticon.svg';
// import contracts from '@/_generated/contracts/contract-abi-etc.json';

export default {
  name: 'RPX',
  name_long: 'RPX Chain',
  homePage: 'https://explorer.rupayx.com/',
  blockExplorerTX: 'https://explorer.rupayx.com/tx/[[txHash]]',
  blockExplorerAddr: 'https://explorer.rupayx.com/address/[[address]]',
  chainID: 5050,
  tokens: tokens,
  contracts: [],
  icon: octium,
  currencyName: 'RPX',
  isTestNetwork: false,
  isEthVMSupported: {
    supported: false,
    url: null,
    websocket: null
  },
  gasPriceMultiplier: 1,
  canBuy: false,
  coingeckoID: null
};
